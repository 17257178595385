.main-contactheader-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
}

.main-contactheader-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 100%;
    gap: 40px;
}

.main-contactheader-content h1 {
    text-align: center;
    font-size: 115px;
    width: 100%;
    margin: 0;
    padding: 0;
}

.main-contactheader-content span {
    padding: 0;
    margin: 0;
    color: #676CE7;
}

.main-contactheader-content p {
    font-size: 32px;
    padding: 0;
    margin: 0;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .main-contactheader-content h1 {
        font-size: 80px;
    }
    .main-contactheader-content p {
        font-size: 24px;
    }
}

@media only screen and (max-width: 480px) {
    .main-contactheader-content h1 {
        font-size: 60px;
    }
    .main-contactheader-content p {
        font-size: 20px;
    }
}
