@keyframes blink1 {
  0% {
    box-shadow: 0px 0px 5.5px 0px #00d95f;
  }
  50% {
    box-shadow: 0px 0px 12px 0px #ffffff;
  }
  100% {
    box-shadow: 0px 0px 5.5px 0px #00d95f;
  }
}
.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}

.chatIcon {
  width: 60px;
  height: 60px;
  background-color: #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgb(55, 255, 0);
  transition: transform 0.3s ease-in-out;
  animation: blink1 1s infinite; /* Corrected animation property */
}

.chatIcon:hover {
  transform: scale(1.3);
}

.chatIcon img {
  width: 40px;
  height: 40px;
}

.chatBox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  color: white;
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 30px rgb(38, 38, 38);
  z-index: 998;
}

.question p {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
}

.ul-li-chatbot ul {
  list-style-type: none;
  padding: 0;
}

.ul-li-chatbot ul li {
  cursor: pointer;
  margin-bottom: 5px;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-size: 12px;
  color: black;
}

.ul-li-chatbot ul li:hover {
  background-color: black;
  color: white;
}

.message p {
  color: green;
}

.selectedOptions {
  margin-top: 10px;
}

.thankYou p {
  font-weight: bold;
}
.chatBox input[type="text"] {
  width: calc(100% - 50px); /* Adjust width to fit button */
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.chatBox button {
  margin-top: 20px;
  width: fit-content;
  height: 40px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chatBox button:hover {
  background-color: #555;
}

.arrow-right {
  position: absolute;
  top: 50%;
  right: -20px; /* Adjust as needed */
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #000;
}

.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}

.chatIcon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

/* Hover effect for chat icon */
.chatIcon:hover {
  transform: scale(1.1);
}

/* Styling for chat icon image */
.chatIcon img {
  width: 40px;
  height: 40px;
  transition: opacity 0.3s ease;
}

/* Styling for active chat icon (showing close icon) */
.chatIcon.active img {
  opacity: 0;
}

.chatBox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  color: white;
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 30px rgba(38, 38, 38, 0.5);
  z-index: 998;
}

/* Styling for chat box questions */
.question p {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
}

/* Styling for chat box options list */
.ul-li-chatbot ul {
  list-style-type: none;
  padding: 0;
}

.ul-li-chatbot ul li {
  cursor: pointer;
  margin-bottom: 5px;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-size: 12px;
  color: black;
}

.ul-li-chatbot ul li:hover {
  background-color: black;
  color: white;
}

/* Styling for success message */
.message p {
  color: green;
}

/* Styling for input field in chat box */
.chatBox input[type="text"] {
  width: calc(100% - 50px); /* Adjust width to fit button */
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

/* Styling for submit button in chat box */
.chatBox button {
  margin-top: 20px;
  width: fit-content;
  height: 40px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chatBox button:hover {
  background-color: #555;
}

.arrow-right {
  position: absolute;
  top: 50%;
  right: -20px; /* Adjust as needed */
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #000;
}
