.main-question-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content-questions-container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;
}

.content-questions-container h3 {
    display: flex;
    align-items: center;
    font-size: 40px;
}

.questions-answers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.questions-answers h4 {
    cursor: pointer;
    font-size: 32px;
    position: relative;
    padding: 0;
    margin: 0;
}

.questions-answers h4::after {
    position: absolute;
    top: 0px;
    left: 70%;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    font-size: 18px;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
}

.questions-answers h4:hover::after {
    visibility: visible;
    opacity: 1;
}

.questions-answers p {
    font-size: 18px;
}



@media (max-width: 480px) {
    .content-questions-container {
        width: 80%; 
    }

    .content-questions-container h3 {
        font-size: 20px; 
    }

    .questions-answers h4 {
        font-size: 15px; 
    }

    .questions-answers h4::after {
        font-size: 16px; 
    }

    .questions-answers p {
        font-size: 16px;
    }
}


@media (min-width: 481px) and (max-width: 768px) {
    .main-question-container{
        width: 100%;
    }
    .content-questions-container {
        width: 80%; 
    }

    .content-questions-container h3 {
        font-size: 36px; 
    }

    .questions-answers h4 {
        font-size: 28px; 
    }

    .questions-answers h4::after {
        font-size: 20px; 
    }

    .questions-answers p {
        font-size: 20px; 
    }
}
