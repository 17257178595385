.project-header-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.project-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center projects horizontally */
  gap: 30px; /* Add spacing between project items */
  max-width: 1200px;
  width: 100%;
}

.project-item {
  width: 45%; /* Adjust width to fit two items in a row */
  margin-bottom: 30px;
  color: #fff;
  text-decoration: none;
  display: flex;
  flex-direction: column; /* Stack text content vertically */
  align-items: center; /* Align text content to the left */
  justify-content: center;
}

.project-details {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  transition: transform 0.3s ease;
  width: 100%; /* Ensure details container takes full width */
}

.project-details:hover {
  transform: translateY(-5px);
}

.project-image {
  width: 100%;
  height: 500px; /* Maintain aspect ratio */
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

.text-content {
  text-align: left;
  width: 100%; /* Ensure text content takes full width */
}

.text-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.text-content p {
  font-size: 16px;
  margin: 0;
}

@media (max-width: 768px) {
  .project-item {
    width: 90%; /* Full width on smaller screens */
  }
}
