.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loader_ring {
  border: 16px solid #ffffff; /* Light grey */
  border-top: 16px solid #4d6900; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-p {
  font-family: sans-serif;
  font-weight: bolder;
  text-align: center;
  color: white;
}
.loader-count {
  z-index: 3;
}
.main-loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
