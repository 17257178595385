.project-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.project-details-container h1 {
  font-size: 48px;
  text-align: center;
  margin-bottom: 20px;
}

.image-content-text-product {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 40px;
}

.gallery {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: 250px;
}

.card {
  width: 70px;
  height: 250px; /* Set the desired height for all images */
  border-radius: 25px;
  overflow: hidden;
  transition: width 0.4s ease-in-out;
  object-fit: cover;
}

.card:hover {
  width: 300px;
  cursor: pointer;
}
.title-information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 600px;
}

.title-information h2 {
  font-size: 36px;
  margin-bottom: 10px;
}

.title-information p {
  font-size: 18px;
  line-height: 1.6;
}

.complete-tour {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-top: 40px;
}

.complete-tour h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.feature-video-section {
  width: 100%;
  display: flex;
  justify-content: center;
}

.feature-video-section iframe {
  width: 80%;
  height: 500px;
  border-radius: 20px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1024px) {
  .project-details-container h1 {
    font-size: 36px;
  }

  .image-content-text-product {
    flex-direction: column;
    align-items: center;
  }

  .gallery {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .title-information h2 {
    font-size: 28px;
  }

  .title-information p {
    font-size: 16px;
  }

  .complete-tour h1 {
    font-size: 28px;
  }

  .feature-video-section iframe {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .project-details-container h1 {
    font-size: 28px;
  }

  .title-information h2 {
    font-size: 24px;
  }

  .title-information p {
    text-align: center;
    font-size: 14px;
  }

  .complete-tour h1 {
    font-size: 24px;
  }

  .feature-video-section iframe {
    width: 95%;
  }
}

@media (max-width: 480px) {
  .project-details-container h1 {
    font-size: 24px;
  }

  .title-information h2 {
    font-size: 20px;
  }

  .title-information p {
    font-size: 12px;
  }

  .complete-tour h1 {
    font-size: 20px;
  }

  .feature-video-section iframe {
    width: 100%;
  }
  .card {
    width: 50px;
    height: 150px;
  }

  .card:hover {
    width: 150px;
    height: 150px;
  }
}
