.main-reviews-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}

.content-container-review {
  display: flex;
  flex-direction: column;
  width: 1200px;
  gap: 20px;
}

.content-container-review h1 {
  font-size: 40px;
  display: flex;
  padding: 0;
  align-items: center;
  margin: 0px 0px 0px 20px;
}

.content1-review {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 50px;
  padding: 20px;
}

.left-review {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 32px 32px 32px 0px;
  transition: opacity 0.8s ease-in-out;
}

.left-review img {
  width: 64px;
  height: 64px;
  border-radius: 50px;
  transition: opacity 0.5s ease-in-out;
  object-fit: cover;
}

.names {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
}

.names h4 {
  font-size: 24px;
  padding: 0;
  margin: 0;
}

.names p {
  font-size: 18px;
  padding: 0;
  margin: 0;
}

.info-review p {
  font-size: 25px;
}

.arrows-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.arrows-container svg {
  cursor: pointer;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

@media (max-width: 1200px) {
  .content-container-review {
    width: 100%;
    padding: 0 20px;
  }

  .content-container-review h1 {
    font-size: 32px;
    margin: 0 0 20px 10px;
  }

  .content1-review {
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 0 20px;
  }

  .left-review {
    padding: 20px;
  }

  .left-review img {
    width: 50px;
    height: 50px;
  }

  .names h4 {
    font-size: 20px;
  }

  .names p {
    font-size: 16px;
  }

  .info-review p {
    font-size: 22px;
    text-align: center;
    padding: 0 10px;
  }
}

@media (max-width: 768px) {
  .content-container-review {
    width: 100%;
    padding: 0 10px;
  }

  .content-container-review h1 {
    font-size: 24px;
    margin: 0 0 20px;
  }

  .content1-review {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 0 10px;
  }

  .left-review {
    padding: 20px;
  }

  .left-review img {
    width: 50px;
    height: 50px;
  }

  .names h4 {
    font-size: 20px;
  }

  .names p {
    font-size: 16px;
  }

  .info-review p {
    font-size: 18px;
    text-align: center;
    padding: 0 10px;
  }
}
