.main-aboutus-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  padding: 0 20px;
}

.main-content-aboutus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  gap: 20px;
  box-sizing: border-box;
}

.main-content-aboutus h1 {
  font-size: 40px;
  flex: 1;
  text-align: left;
}

.main-content-aboutus p {
  flex: 2;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .main-content-aboutus {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .main-content-aboutus h1 {
    font-size: 32px;
    margin-bottom: 20px;
    text-align: center;
  }

  .main-content-aboutus p {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .main-content-aboutus h1 {
    font-size: 24px;
  }

  .main-content-aboutus p {
    font-size: 14px;
    width: 100%;
    text-align: center;
  }
}
