.main-founder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 20px;
  padding: 0 20px;
}

.main-founder-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='314' height='484' viewBox='0 0 314 484' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M173 73.3441C178 99.8441 201.75 209.882 139.021 139.516C98 93.5 225.821 76.7198 269.521 73.3441M269.521 73.3441C318.869 69.532 341.105 92.3822 243.522 151.516C59.5215 263.016 -37.9785 122.516 17.5215 278.516C73.0215 434.516 92.5215 552.016 139.021 434.516C185.521 317.016 335.022 582.516 243.522 263.016C152.021 -56.4841 186.521 -33.3278 269.521 73.3441Z' stroke='white' stroke-width='3'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.3;
  z-index: -1;
}

.jatin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  gap: 50px;
}

.content-jatin,
.content-lalit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;
}

.content-jatin img,
.content-lalit img {
  width: 32%;
  height: auto;
  max-width: 300px;
}

.content-jatin h1 {
  font-size: 56px;
  width: 30%;
  min-width: 250px;
  text-align: left;
}
.content-lalit h1 {
  font-size: 56px;
  width: 30%;
  min-width: 250px;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .jatin-container {
    width: 100%;
    padding: 0 10px;
  }

  .content-jatin,
  .content-lalit {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .content-jatin img,
  .content-lalit img {
    width: 70%;
    margin-bottom: 20px;
  }

  .content-jatin h1,
  .content-lalit h1 {
    font-size: 36px;
    text-align: center;
    width: 80%;
  }
}

@media screen and (max-width: 480px) {
  .content-jatin img,
  .content-lalit img {
    width: 90%;
  }

  .content-jatin h1,
  .content-lalit h1 {
    font-size: 24px;
    width: 90%;
  }
}
