.main-experience-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-container-all {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  max-width: 1200px;
}

.content-container-all h3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-size: 32px;
}

.member-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.container-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid white;
  padding-bottom: 20px;
}

.container-1 h2 {
  font-size: 20px;
  margin: 0;
}

.name-experience {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.name-experience h5 {
  font-size: 18px;
  margin: 0;
}

.name-experience p {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .content-container-all h3 {
    margin-top: 30px;
    font-size: 28px;
  }
  .container-1 h2 {
    padding: 20px 0px 20px 0px;
  }
}

@media screen and (max-width: 480px) {
  .content-container-all h3 {
    font-size: 24px;
  }
  .container-1 h2 {
    padding: 20px 0px 20px 0px;
  }
}
