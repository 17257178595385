.blogs-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
  padding: 0 10px;
}

.blogs-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  gap: 20px;
  box-sizing: border-box;
}

.upper-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.upper-section h2 {
  font-size: 40px;
}
.content-container1 a {
  color: white;
  background-color: black;
}
.upper-section button {
  background-color: black;
  color: white;
  border: none;
  padding: 10px;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-container1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.content-container1 img {
  width: 300px;
  height: 190px;
  object-fit: cover;
  border-radius: 12px;
}

.text-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.text-container p {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.text-container h4 {
  font-size: 38px;
  margin: 0;
  padding: 0;
}

.buttons button,
.read-button {
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 25px;
  width: fit-content;
  font-weight: 600;
  transition: transform 0.3s ease-in-out;
}

.buttons button:hover,
.read-button:hover {
  transform: scale(1.04);
}

@media (max-width: 992px) {
  .upper-section h2 {
    font-size: 32px;
  }

  .content-container1 {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .content-container1 img {
    width: 90%;
    height: auto;
  }

  .text-container h4 {
    font-size: 32px;
  }

  .text-container p {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .upper-section {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .upper-section h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }

  .content-container1 {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .content-container1 img {
    width: 80%;
    height: auto;
  }

  .text-container h4 {
    font-size: 24px;
  }

  .text-container p {
    font-size: 14px;
  }

  .buttons button,
  .read-button {
    font-size: 16px;
    padding: 8px 16px;
  }
}

@media (max-width: 576px) {
  .upper-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .content-container1 {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .content-container1 img {
    width: 80%;
    height: auto;
  }

  .text-container h4 {
    font-size: 20px;
  }

  .text-container p {
    font-size: 14px;
  }

  .buttons button,
  .read-button {
    font-size: 14px;
    padding: 6px 12px;
  }
}
