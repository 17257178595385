.custom-experience-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.custom-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  max-width: 1200px;
}

.custom-content-container h3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  font-size: 32px;
}

.custom-member-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.custom-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid white;
  padding-bottom: 20px;
}

.custom-container h2 {
  font-size: 20px;
  margin: 0;
}

.custom-name-experience {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.custom-name-experience h5 {
  font-size: 18px;
  margin: 0;
}

.custom-name-experience p {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .custom-content-container h3 {
    font-size: 28px;
  }
  .custom-container h2 {
    padding: 20px 0px 20px 0px;
  }
}

@media screen and (max-width: 480px) {
  .custom-content-container h3 {
    font-size: 24px;
  }
  .custom-container h2 {
    padding: 20px 0px 20px 0px;
  }
}
