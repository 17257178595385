.popup-main-container {
    min-height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(23, 23, 23, 0.8);
    opacity: 0;
    animation: fadeIn 0.5s ease-out 0.5s forwards;
    z-index: 999; 
  }
  
  .popup-main-container.show {
    animation: fadeOut 2s ease-in;
    opacity: 1;
  }
  
  .popup-contents {
    width: 320px;
    height: auto;
    padding: 20px; 
    border-radius: 8px;
    background: linear-gradient(97deg, #070707 0%, #262626 99.32%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  
  .popup-contents h1 {
    color: white;
    text-align: center;
    font-family: Manrope;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 15px; 
  }
  
  .popup-contents button {
    width: 140px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #fff;
    border-radius: 4px;
    background: linear-gradient(91deg, #001f3f 0.3%, #001f3f 100%);
    font-family: Manrope;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  
  .close svg {
    width: 100%;
    height: 100%;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  