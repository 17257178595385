body,
html {
  margin: 0;
  padding: 0;
}

/* Base styles */
body {
  background-color: black;
  font-family: "Syne", sans-serif;
  color: white;
}

/* Container styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  /* Standard animation */
  animation: App-logo-spin infinite 20s linear;
  /* Vendor-prefixed animation */
  -webkit-animation: App-logo-spin infinite 20s linear;
}

/* Header styles */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Link styles */
.App-link {
  color: #61dafb;
}

/* Sub-footer styles */
.sub-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 50px;
}

.sub-footer a {
  color: white;
  text-decoration: none;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.sub-footer a:hover {
  transform: scale(1.1);
}

/* Animation keyframes */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Vendor-prefixed animation keyframes */
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

/* Media Queries */

/* Small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .App-header {
    font-size: calc(8px + 2vmin);
  }

  .sub-footer {
    margin-bottom: 30px;
  }

  .sub-footer a {
    font-size: 14px;
  }
}

/* Medium devices (tablets, 600px to 768px) */
@media (min-width: 600px) and (max-width: 768px) {
  .App-header {
    font-size: calc(9px + 2vmin);
  }

  .sub-footer {
    margin-bottom: 40px;
  }

  .sub-footer a {
    font-size: 16px;
  }
}

/* Large devices (desktops, 768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .App-header {
    font-size: calc(10px + 2vmin);
  }

  .sub-footer {
    margin-bottom: 50px;
  }

  .sub-footer a {
    font-size: 18px;
  }
}

/* Extra large devices (large desktops, 1024px and up) */
@media (min-width: 1024px) {
  .App-header {
    font-size: calc(11px + 2vmin);
  }

  .sub-footer {
    margin-bottom: 60px;
  }

  .sub-footer a {
    font-size: 20px;
  }
}
