.main-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 80px;
  animation: fadeIn 1s ease forwards;
  text-align: center;
}
.main-header-container p{
  display: flex;
  text-align: center;
  font-size: 18px;
}
.header-title {
  font-size: 100px;
  color: white;
  font-family: "Syne", sans-serif;
  font-weight: 700;
  transition: font-size 0.3s ease;
}

.header-description {
  font-size: 20px;
  color: #93989d;
  font-family: "Syne", sans-serif;
  width: 70%;
  text-align: center;
  transition: font-size 0.3s ease, width 0.3s ease;
}

.header-cta {
  margin-top: 40px;
}

.cta-button {
  padding: 12px 24px;
  font-size: 18px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  background-color: black;
  color: white;
  margin-right: 20px;
  border: 2px solid white;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-decoration: none;
}

.cta-button.secondary {
  background-color: transparent;
  color: white;
  border: 2px solid white;
}

.cta-button:hover {
  background-color: white;
  color: black;
}

.cta-button.secondary:hover {
  background-color: #fff;
  color: black;
}

@media screen and (max-width: 768px) {
  .header-title {
    font-size: 48px;
  }

  .header-description {
    font-size: 18px;
    width: 80%;
  }

  .cta-button {
    font-size: 16px;
    padding: 10px 20px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 480px) {
  .main-header-container {
    margin-top: 40px;
  }

  .header-title {
    font-size: 36px;
  }

  .header-description {
    font-size: 16px;
    width: 90%;
  }

  .cta-button {
    font-size: 14px;
    padding: 8px 16px;
    margin-right: 5px;
  }

  .header-cta {
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
  }

  .cta-button.secondary {
    margin-top: 10px;
  }
}

/* Animation for fadeIn */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
