.text-main-component {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    overflow: hidden;
}

.text-main-component .text-animations {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.text-main-component p {
    display: flex;
    flex-direction: row;
    font-size: 56px;
    gap: 40px;
}

.text-main-component .text-item {
    padding: 20px;
    font-size: 56px;
    color: black;
    font-weight: 700;
    white-space: nowrap;
    -webkit-text-stroke: 3px white;
    text-stroke: 1px white;
    animation: move-rtl 15s linear infinite;
}

@keyframes move-rtl {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}


@media (max-width: 480px) {
    p {
        font-size: 32px; 
        gap: 20px; 
    }

    .text-main-component .text-item {
        font-size: 32px; 
        padding: 10px; 
    }
}


@media (min-width: 481px) and (max-width: 768px) {
    p {
        font-size: 48px; 
        gap: 30px; 
    }

    .text-main-component .text-item {
        font-size: 48px; 
        padding: 15px; 
    }
}
