.contact-form-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.contact-form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  width: 100%;
}

.contact-form-component form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  width: 100%;
  font-family: "Nexa", sans-serif;
}

.contact-form-content input {
  width: 80%;
  font-family: "Nexa", sans-serif;
  color: white;
  background-color: black;
  font-size: 64px;
  outline: none;
  border: none;
  border-bottom: 1px solid rgb(255, 255, 255);
  padding: 20px;
}
.contact-form-content input[type="number"] {
  width: 80%;
  font-family: "Nexa", sans-serif;
  color: white;
  background-color: black;
  font-size: 64px;
  outline: none;
  border: none;
  border-bottom: 1px solid rgb(255, 255, 255);
  padding: 20px;

  -moz-appearance: textfield;
}

.contact-form-content input[type="number"]::-webkit-inner-spin-button,
.contact-form-content input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 50px;
  cursor: pointer;
}

.form-button button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: black;
  color: white;
  font-size: 64px;
  font-family: "Nexa", sans-serif;
  border: none;
  cursor: pointer;
}

.form-button button svg {
  height: 150px;
  width: 150px;
}

@media only screen and (max-width: 768px) {
  .contact-form-content input[type="number"] {
    font-size: 40px;
    padding: 15px;
  }
  .contact-form-content input {
    font-size: 40px;
    padding: 15px;
  }
  .form-button button {
    font-size: 40px;
  }
  .form-button button svg {
    height: 100px;
    width: 100px;
  }
}

@media only screen and (max-width: 480px) {
  .contact-form-content input[type="number"] {
    font-size: 32px;
    padding: 10px;
  }
  .contact-form-content input {
    font-size: 32px;
    padding: 10px;
  }
  .form-button button {
    font-size: 18px;
  }
  .form-button button svg {
    height: 40px;
    width: 40px;
  }
}
