.main-expertise-component {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 60px;
  }
  
  .expertise-feild {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%; 
    max-width: 1100px; 
  }
  
  .expertise-feild h2 {
    text-align: left;
    font-size: 40px;
    font-family: "Syne", sans-serif;
    font-weight: 700;
  }
  
  .multiple-field {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; 
  }
  
  .field {
    padding: 10px;
    width: 45%; 
  }
  
  .field h3 {
    margin-top: 0; 
    margin-bottom: 10px; 
  }
  
  .field p {
    margin-top: 0;
    margin-bottom: 0; 
    text-align: left;
    font-size: 16px;
    font-family: "Syne", sans-serif;
    color: #CBCBCB;
  }
  

  @media screen and (max-width: 768px) {
    .field {
      width: 40%; 
    }
  }
  
  /* For mobile */
  @media screen and (max-width: 480px) {
    .field {
      width: 100%;
    }
  }
  