
.main-footer-componenet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%; 
    max-width: 1200px; 
    height: 90px;
}

.left-footer p {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    padding: 0;
    margin: 0;
}

.right-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.right-container p a {
    padding: 10px;
    color: white;
    text-decoration: none;
    font-size: 14px;
}


@media (max-width: 480px) {
    .content-footer {
        flex-direction: column; 
        height: auto; 
        align-items: center; 
    }

    .left-footer,
    .right-container {
        margin-bottom: 20px; 
    }
}


@media (min-width: 481px) and (max-width: 768px) {
    .content-footer {
        height: auto; 
        align-items: center;
    }
}
