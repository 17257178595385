.main-testimonial-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main-content-testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; 
    max-width: 1200px;
}

.main-content-testimonial p {
    font-size: 16px; 
    width: 80%;
}

.top-container-testimonial {
    display: flex;
    flex-direction: row; 
    width: 80%;
    justify-content: space-between;
}

.top-container-testimonial h1 {
    font-size: 42px; 
}

.main-content-testimonial h4 {
    width: 80%;
    font-size: 20px; 
}

@media screen and (max-width: 768px) {
    .top-container-testimonial{
        width: 80%;
    }
    .top-container-testimonial h1 {
        font-size: 36px; 
    }
    
    .top-container-testimonial svg {
        width: 100px; /* Adjusted width of SVG for smaller screens */
        height: auto; /* Maintain aspect ratio */
    }
}

@media screen and (max-width: 480px) {
    .top-container-testimonial h1 {
        font-size: 20px; 
    }

    .main-content-testimonial p {
        font-size: 14px; 
    }
    
    .top-container-testimonial svg {
        width: 80px; /* Further decrease width of SVG for even smaller screens */
        height: auto; 
    }
}
