.main-navbar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #000000;
  height: 80px;
  padding: 0 20px;
}

.middle-container {
  flex-grow: 1; /* Allow the middle-container to grow and take up remaining space */
  display: flex;
  justify-content: center; /* Center the items horizontally */
}

.middle-container ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.middle-container li {
  margin: 0;
}

.middle-container li a {
  color: white;
  text-transform: capitalize;
  text-decoration: none;
  padding: 10px;
  font-size: 14px;
  font-family: "Syne", sans-serif;
  border-radius: 25px;
  transition: all 0.3s ease-in-out;
}

.middle-container li a:hover {
  background-color: white;
  color: black;
  transform: scale(1.02);
}

.middle-container li a.active {
  background-color: white;
  color: black;
  transform: scale(1.02);
}

.right-container {
  display: flex;
  align-items: center;
}

.right-container button {
  background-color: transparent;
  color: white;
  border: 2px solid white;
  font-family: "Syne", sans-serif;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.left-container img {
  max-width: 150px;
  height: auto;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .main-navbar-container {
    height: auto;
    flex-direction: column;
    padding: 20px;
    align-items: center; /* Center items vertically */
  }

  .middle-container {
    margin-top: 20px;
  }

  .middle-container ul {
    gap: 20px;
  }

  .left-container {
    display: none;
  }

  .right-container button {
    display: none;
    margin-top: 20px;
  }
}

@media screen and (max-width: 480px) {
  .middle-container ul {
    display: flex;
    width: 100%;
    background-color: #010208;
    text-align: center;
  }

  .middle-container {
    margin-top: 20px;
  }

  .left-container {
    display: none;
  }

  .right-container button {
    display: none;
    margin-top: 20px;
  }
}
