.blog-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 70px;
  padding: 20px;
}

.blog-details-container img {
  max-width: 100%;
  width: 60%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.details-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  margin-top: 20px;
  text-align: left;
  padding: 20px;
  background-color: black;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.details-text p {
  font-size: 16px;
  color: #666;
  text-align: left;
}

.details-text h2 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .details-text {
    padding: 10px;
  }

  .details-text h2 {
    font-size: 28px;
  }
}

@media (max-width: 480px) {
  .details-text h2 {
    font-size: 24px;
  }
}

.loading {
  font-size: 24px;
  text-align: center;
  margin-top: 50px;
}
